import { Link } from "gatsby"
import React from "react"

export const ServiceSectionEs = () => {
  return (
    <section>
      <div className="free-services">
        <p className="title">
          Todos los servicios mencionados previamente son gratuitos.
        </p>
        <p className="text">
          Contáctenos y permítanos ayudarle a encontrar tu trabajo ideal.
        </p>
        <Link to="/es/job-form" className="free-consultation-btn ">
          <button>Consulta Gratuita</button>
        </Link>

        <ul className="mt-40">
          <li>Todos los servicios que ofrecemos son gratuitos.</li>
          <li>
            Tenga en cuenta que se requiere una entrevista de registro para
            comenzar a presentar opciones laborales específicas.
          </li>
          <li>Su registro no garantiza un puesto de trabajo o empleo</li>
          <li>
            Dependiendo de sus preferencias y experiencia, puede resultar
            difícil encontrar oportunidades adecuadas de trabajo, apreciamos su
            comprensión.
          </li>
        </ul>
      </div>
    </section>
  )
}
