import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Seo } from "../../components/seo"
//import useLocation from "../hooks/useLocation"
import { ContactSectionEs } from "../../components/es/ContactSectionEs"
import { ServiceSectionEs } from "../../components/es/ServicesSectionEs"
import JobFormEs from "../../components/forms/JobFormEs"

//IMG
import mobile_banner from "../../images/home/mobile-hero.webp"
//Gif
import mainVideoBanner from "../../images/home/919m-hero.mp4"

import makeHappy from "../../images/home/en/makeHappy.png"

// Header Images
import header1 from "../../images/home/en/header1.webp"
import header2 from "../../images/home/en/header2.webp"
import header3 from "../../images/home/en/header3.webp"
import header4 from "../../images/home/en/header4.webp"

import benefits from "../../images/home/en/benefits.webp"
import opportunities from "../../images/home/en/opportunities.webp"
import interview from "../../images/home/en/interview.webp"

const IndexEsPage = () => {
  const query = useStaticQuery(graphql`
    {
      allStrapiJobs(
        sort: { order: DESC, fields: DatePublication }
        limit: 8
        filter: { Archived: { eq: false }, Language: { eq: "Japanese" } }
      ) {
        nodes {
          JobDescription
          JobCategorie
          Allowances
          HolidayVacation
          CompanyProfile
          Insurance
          Manualid
          Salary
          SalaryIncrease
          Title
          WorkingTime
          Archived
          DatePublication(formatString: "YYYY年 MM月 DD日")
          locations {
            name
          }
          marks {
            name
          }
          types {
            name
            image {
              url
            }
          }
        }
      }
      allStrapiTitles(
        limit: 5
        sort: { order: DESC, fields: DatePublication }
      ) {
        nodes {
          strapiId
          Content
          Title
          Description
          Slug
          Image {
            url
          }
          LastUpdate
          DatePublication
          article_categories {
            Name
            Slug
          }
        }
      }

      file(relativePath: { eq: "03_slide_0222.webp" }) {
        url
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const imageLog = query.file.url

  // Header Images
  const headerImg = [
    {
      img: header1,
    },
    {
      img: header2,
    },
    {
      img: header3,
    },
    {
      img: header4,
    },
  ]
  const ourSolution = [
    {
      img: benefits,
    },
    {
      img: opportunities,
    },
    {
      img: interview,
    },
  ]
  const howWorks = [
    {
      number: "1",
      name: "Solicitud",
    },
    {
      number: "2",
      name: "Orientación",
    },
    {
      number: "3",
      name: "Busqueda de empleo",
    },
    {
      number: "4",
      name: "Selección y entrevista",
    },
    {
      number: "5",
      name: "Oferta de empleo e inicio de labores",
    },
  ]

  return (
    <div className="english-page-body">
      <div className="banner-video">
        <video width="600" autoPlay muted loop className="no-show-mobile">
          <source src={mainVideoBanner} type="video/webm" />
        </video>
        <img
          src={mobile_banner}
          alt="mobile-banner"
          className="show-mobile mob-banner-en"
        />
        <h1 aria-label="Career in Mexico!" className="title-banner">
          Empleos en empresas japonesas
        </h1>
        <Link to="/es/job-form" className="button button-en">
          <button className="btn-en"> Consulta Gratuita</button>
        </Link>
      </div>
      <section style={{ marginTop: "-3.5px" }}>
        <div className="search-support pl-15 pr-15">
          <p className="search-support_title">
            Soporte en la búsqueda de empleo SIN COSTO
          </p>
          <p className="search-support_text">
            Únete y deja que nuestros expertos te ayuden a encontrar tu trabajo
            ideal.
          </p>
        </div>
        <div className="search-header-img">
          {headerImg.map((image, i) => (
            <img src={image.img} alt={"imagenCabecera_" + i} />
          ))}
        </div>
      </section>

      <section className="home-page home-page_en">
        <div className="row">
          <section>
            <h2>¿Por qué elegir nuestro servicio?</h2>
            <div className="our-solutions mb-50">
              <img src={ourSolution[0].img} alt="beneficios" />
              <div className="our-solutions_paragraph">
                <p className="our-solutions_paragraph_title">
                  Prestaciones y Beneficios
                </p>
                <p className="our-solutions_paragraph_text">
                  QGM presenta trabajos que vienen con prestaciones atractivas
                  como seguro de gastos médicos mayores, fondo de ahorros ,
                  vales de despensa, etc... que le dará estabilidad y/o
                  crecimiento a la carrera profesional de los candidatos.
                </p>
              </div>
            </div>
            <div className="our-solutions our-solutions_invert mb-50">
              <div className="our-solutions_paragraph">
                <p className="our-solutions_paragraph_title">
                  Oportunidades laborales únicas
                </p>
                <p className="our-solutions_paragraph_text">
                  QGM ofrece infomación de vacantes exclusivas, que no se
                  encuentran en los sitios de empleo tradicionales. Estos
                  trabajos ofrecen exelentes beneficios y oportunidades de
                  crecimiento profesional, solamente podras acceder a estas
                  mediante nosotros.
                </p>
              </div>
              <img src={ourSolution[1].img} alt="oportunidades mb-50" />
            </div>
            <div className="our-solutions mb-50">
              <img src={ourSolution[2].img} alt="entrevista" />
              <div className="our-solutions_paragraph">
                <p className="our-solutions_paragraph_title">
                  Apoyo para la preparación de entrevistas
                </p>
                <p className="our-solutions_paragraph_text">
                  QGM ofrece una preparación exahustiva para las entrevistas,
                  guiando a los candidatos con tips personalizados, preguntas
                  específicas de la empresa y técnicas para aumentar la
                  confianza, garantizando mejores posibilidades de tener éxito
                  en las entrevistas.
                </p>
              </div>
            </div>
            <p className="our-solutions_bottom">
              * Nuestro servicio de reclutamiento es para empleos directos o de
              tiempo indeterminado, no ofrecemos servicio de OUTSOURCING o
              subcontratación.
            </p>
          </section>
          <ContactSectionEs />
          <section>
            <h2>¿COMÓ FUNCIONA?</h2>
            <div className="how-works">
              <p className="text">
                Desde que se registra en nuestro servicio, recibe ofertas de
                trabajo incluso desde los primeros meses de registro, ofrecemos
                asitencia personalizada en cada paso del proceso. Para obtener
                una explicación, visite
                <Link to="/es/process"> más detalles</Link>.
              </p>
              <div className="how-works_flow">
                {howWorks.map(content => (
                  <div className="how-works_flow_arrow">
                    <p className="how-works_flow_number">{content.number}</p>
                    <div className="how-works_flow_box">
                      <p>{content.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <div className="pr-30 pl-30" style={{ width: "100%" }}>
            <ServiceSectionEs />
          </div>
          <section
            style={{
              backgroundImage: "url(" + makeHappy + ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              backgroundSize: "cover",
              padding: "47px 0",
              position: "relative",
            }}
          >
            <div className="layer"></div>
            <div className="layer2"></div>

            <div className="make-happy max-width-size pt-50 pb-50 pl-30 pr-30">
              <h3>“Hacemos felices a todos los que nos rodean”</h3>
              <p>
                Somos una compañía japonesa de reclutamiento y selección de
                personal que brinda sus servicios principalmente a las empresas
                japonesas establecidas en México. Contamos con oficinas en
                Aguascalientes y Querétaro.
                <br />
                <br />
                En QUICK GLOBAL MEXICO, S.A. DE C.V. , nos comprometemos y
                esforzamos en ser consultores confiables y ofrecemos un servicio
                personalizado para cada persona en busca de empleo, así como
                para las empresas en búsqueda de recursos humanos, siendo
                nuestra prioridad, la satisfacción de ambas partes.
              </p>
            </div>
          </section>
          <section>
            <h2 className="h2-form">FORMULARIO DE CONSULTA</h2>
            <div className="consultation-form">
              <p className="text">
                Nuestra empresa se esfuerza por satisfacer las preferencias de
                nuestros solicitantes en la medida de lo posible. Podemos
                negociar las condiciones de empleo con las empresas contratantes
                en su nombre si es necesario. Tenga en cuenta que es posible que
                no se cumplan algunas solicitudes.
              </p>
            </div>
            <JobFormEs />
          </section>
        </div>
      </section>
    </div>
  )
}

export const Head = () => {
  return (
    <Seo
      title="Ofertas de trabajo para México, cambio de carrera e información de empleo [QUICK GLOBAL MEXICO】"
      description="Ofertas de trabajo para México, cambio de carrera e información de empleo [QUICK GLOBAL MEXICO】"
      url={`https://www.919mexico.com/es`}
      image="https://res.cloudinary.com/dkskzhwoe/image/upload/v1738628213/919mexico_thumbnail_228c04bd38.png"
    />
  )
}


export default IndexEsPage
