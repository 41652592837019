import { Link } from "gatsby"
import React from "react"

export const ContactSectionEs = () => {
  return (
    <section className="bg-cyan">
      <div className="contact-section-en pt-50 pb-50">
        <p className="title">¿BUSCAS EMPLEO?</p>
        <p className="text">
          Estamos para ayudarte en cualquier momento. ¡Solo contáctanos!
        </p>
        <Link to="/es/job-form">
          <button>Consulta gratuita</button>
        </Link>
      </div>
    </section>
  )
}
